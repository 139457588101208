/*
    General Css
    header, body, general font, colours
*/

body {
  font-family: 'Inter', sans-serif;
  color: #020202;
  overflow-y: hidden;
  overflow-x: hidden;
}

p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.header-logo {
  width: 64px;
}

.header-container {
  width: calc( 100% - 128px );
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.header-app-button {
  border: 1px solid #EEEEEE;
  height: 34px;
  width: 108px;
  text-align: center;
  display: inline-block;
  float: right;
  border-radius: 24px;
  margin-top: 7px;
  font-weight: 500;
  padding-top: 14px;
  font-size: 16px;
  color: #020202;
  cursor: pointer;
  transition: 0.15s;
  text-decoration: none;
}

.header-app-button:hover {
  color: #52A8AE;
  border-color: #52A8AE;
}

.header-app-button:active {
  background-color: #DAFDFF;
}

.content-container {
  padding-top: 120px;
}

/*
    End of The General Css
*/



/*
    Welcome page css
 */

.welcome-page-small-header {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.welcome-page-container {
  width: 456px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: calc( 50% - 228px );
  transition: 350ms;
  top: 228px;
}

.welcome-page-logo {
  position: absolute;
  width: 109px;
  left: -64px;
  top: 0px;
}

.welcome-page-logo-container {
  position: relative;
  height: 110px;
}

.welcome-page-main-header {
  font-size: 72px;
  font-weight: 700;
  text-align: right;
  padding-top: 9px;
}

.welcome-page-description {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0px;
}

.welcome-page-start-button {
  width: 228px;
  height: 56px;
  background-color: #52A8AE;
  border-radius: 4px;
  text-align: center;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  cursor: pointer;
  transition: 0.15s;
  outline:none
}

.welcome-page-start-button:hover {
  background-color: #30858D;
}

.welcome-page-start-button:active {
  background-color: #257A82;
  outline: none;
}

/*
    End of welcome page css
 */



/*
    How to use pages' general css elements
 */

.how-to-use-page-main-header {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

.how-to-use-page-main-header-center-align {
  text-align: center;
}

.how-to-use-page-container {
  width: 400px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: absolute;
  left: calc( 50vw - 185px );
  transition: top  350ms, bottom  350ms, left 350ms, right 350ms;
  top: 228px;
  padding-bottom: 50px;
}

.page-indicator-dots-container {
  transition: 350ms;
  top: 242px;
  position: absolute;
  left: calc( 50vw - 250px );
}

.page-indicator-dot {
  width: 13px;
  height: 13px;
  border-radius: 7px;
  background-color: #E5E5E5;
  margin-bottom: 16.5px;
  cursor: pointer;
}

.page-indicator-dot-filled {
  background-color: #52A8AE;
}

.check-caption-ed-page-logo {
  width: 82px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.how-to-use-page-small-header {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.8px;
  margin-top: 5px;
  margin-bottom: 16px;
}

.how-to-use-page-main-header-open-caption-ed {
  width: 265px;
}

.how-to-use-pages-default-button {
  text-decoration: none;
  line-height: 55px;
  margin-top: 25px;
  margin-left: -10px;
  text-align: center;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: 0.15s;
  outline: none;
  display: block;
  border-radius: 28px;
  height: 56px;
  background-color: #52A8AE;
  padding-left: 30px;
  padding-right: 30px;
  float: left;
}

.how-to-use-pages-default-button:hover {
  background-color: #30858D;
}

.how-to-use-pages-default-button:active {
  background-color: #257A82;
  outline: none;
}

.how-to-use-pages-secondary-button {
  text-decoration: none;
  line-height: 55px;
  margin-top: 15px;
  margin-left: -10px;
  text-align: center;
  border: 1px solid #EEEEEE;
  color: #020202;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: 0.15s;
  outline: none;
  display: block;
  border-radius: 28px;
  height: 56px;
  background-color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;
  float: left;
}

.how-to-use-pages-secondary-button:hover {
  color: #52A8AE;
  border-color: #52A8AE;
}

.how-to-use-pages-secondary-button:active {
  color: #52A8AE;
  border-color: #52A8AE;
  background-color: #DAFDFF;
}

.how-to-use-pages-default-button-center {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.how-to-use-pages-default-button-without-left {
  margin-left: 0px;
}

/*
    End of How to use pages' general css elements
 */



/*
    Open Caption.ed Page css elements
 */

.open-caption-ed-logo {
  width: 67px;
  top: 302px;
  right: 115px;
  position: absolute;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 34px;
}

.open-caption-ed-showing-arrow {
  position: absolute;
  left: calc( 50% + 100px );
  top: -217px;
  width: calc( 50vw - 300px );
  height: 557px;
}

.open-caption-website-bar-image {
  margin-top: 30px;
  margin-bottom: 15px;
  margin-left: -141px;
  display: block;
}

/*
    End of Open Caption.ed Page css elements
 */



/*
    Controls of caption.ed css elements
 */

.how-to-use-control-image {
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.5);
  margin-top: 100px;
  margin-bottom: 110px;
}

.how-to-use-page-control-image-text-arrow-1 {
  position: absolute;
  top: 199px;
  left: -7px;
  transition: 0.45s;
  height: 63px;
}

.how-to-use-page-control-image-text-arrow-1-before-animation {
  height: 0px;
}

.how-to-use-page-caption-image-text-arrow-2-before-animation {
  height: 0px !important;
  width: 0xp !important;
}

.how-to-use-page-caption-image-text-arrow-1-before-animation {
  height: 0px !important;
}

.how-to-use-page-control-image-text-arrow-2 {
  position: absolute;
  top: 240px;
  right: 24px;
  width: 166px;
  height: 93px;
  transition: 0.45s;
}

.how-to-use-page-control-image-text-arrow-2-before-animation {
  width: 0px;
  height: 0px;
}

.how-to-use-page-control-image-text-arrow-3 {
  position: absolute;
  top: 410px;
  right: 22px;
  transition: 0.45s;
  width: 115px;
  height: 24px;
}

.how-to-use-page-control-image-text-arrow-3-before-animation {
  width: 0px;
  height: 0px;
}

.how-to-use-page-control-image-text-arrow-4 {
  position: absolute;
  bottom: 180px;
  transition: 0.45s;
  left: 120px;
  width: 44px;
  height: 142px;
}

.how-to-use-page-control-image-text-arrow-4-before-animation {
  width: 0px;
  height: 0px;
}

.how-to-use-page-control-image-text-1 {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 16px;
  top: 148px;
  line-height: 24px;
  left: 0px;
  transition: 0.85s;
}

.how-to-use-page-control-image-text-1-before-animation {
  position: fixed;
  left: -51vw;
}

.how-to-use-page-caption-image-text-1-before-animation {
  position: fixed !important;
  left: -60vw !important;
}

.vjs-big-play-button {
  top: calc( 50% - 20px ) !important;
  left: calc( 50% - 45px ) !important;
}

.how-to-use-page-control-image-text-2 {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 16px;
  width: 210px;
  top: 228px;
  right: -195px;
  transition: 0.85s;
}

.how-to-use-page-control-image-text-2-before-animation {
  position: absolute;
  right: -51vw;
}

.how-to-use-page-caption-image-text-2-before-animation {
  position: fixed !important;
  left: -51vw !important;
}

.how-to-use-page-control-image-text-3 {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 16px;
  width: 210px;
  top: 391px;
  right: -195px;
  transition: 0.85s;
}

.how-to-use-page-control-image-text-3-before-animation {
  position: absolute;
  right: -51vw;
}

.how-to-use-page-control-image-text-4 {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 16px;
  width: 210px;
  bottom: 133px;
  right: 22px;
  transition:  bottom 0.25s;
  visibility: visible;
}

.how-to-use-page-control-image-text-4-before-animation {
  position: absolute;
  bottom: 0px;
  visibility: hidden;
}

/*
    End of Controls of caption.ed css elements
 */

/*
    Login info css elements
 */

.login-info-page-info-text-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
}

.login-info-page-info-text-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 12px;
}

.login-info-page-info-text-3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 5px;
  max-width: 300px;
}

.login-info-page-arrow-1-img {
  position: absolute;
  top: 248px;
  left: -8px;
}

.login-info-page-arrow-2-img {
  position: absolute;
  top: 488px;
  left: 28px;
}

.login-info-page-img {
  width: 252px !important;
}

/*
    End of Login info css elements
 */

/*
    Pin Caption.Ed css elements
 */

.pin-ed-page-list-text-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: left;
  margin-top: 45px;
  position: relative;
}

.pin-ed-page-list-text-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: left;
  margin-top: 38px;
  margin-bottom: 45px;
}

.pin-ed-page-list-tip {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 10px;
}

.pin-ed-page-list-tip > span {
  color: #1A73E8;
  font-weight: 500;
}

.pin-ed-page-extension-icon {
  position: absolute;
  bottom: -12px;
  left: 190px;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 25px;
}

.pin-ed-page-pinned-circle-image {
  position: absolute;
  top: 265px;
  left: 124px;
}

.pin-ed-page-pinned-circle-part {
  position: absolute;
  top: 452px;
  left: 229px;
}

.installation-page-add-chrome-button {
  width: 205px;
  height: 40px;
  border-radius: 4px;
  background-color: #1A73E8;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  font-family: 'Inter', sans-serif;
  margin-top: 25px;
  border: none;
  transition: 0.15s;
  text-decoration: none;
  display: block;
  margin-left: 60px;
  color: white;
  margin-bottom: 15px;
  outline: none;
}

/*
    End of Pin Caption.Ed css elements
 */



/*
    Caption window css elements
 */

.how-to-use-caption-window-image {
  margin-top: 125px;
  margin-left: -66px;
  margin-bottom: 97px;
  width: 551px;
}

.how-to-use-caption-window-image-as-html {
  width: 556px;
  height: 94px;
  background-color: white;
  margin-top: 127px;
  margin-left: -61px;
  margin-bottom: 97px;
  display: block;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
  border-radius: 5px;
  position: relative;
}

.how-to-use-caption-window-image-as-html-circle-1 {
  width: 8px;
  border-radius: 4px;
  height: 8px;
  background-color: #FD6C64;
  position: absolute;
  top: 9px;
  left: 9px;
}

.how-to-use-caption-window-image-as-html-circle-2 {
  width: 8px;
  border-radius: 4px;
  height: 8px;
  background-color: #FBC057;
  position: absolute;
  top: 9px;
  left: 21px;
}

.how-to-use-caption-window-image-as-html-circle-3 {
  width: 8px;
  border-radius: 4px;
  height: 8px;
  background-color: #D8D8D8;
  position: absolute;
  top: 9px;
  left: 33px;
}

.how-to-use-caption-window-image-as-html-logo {
  position: absolute;
  top: 7px;
  right: 9px;
  width: 18px;
}

.how-to-use-page-caption-image-text-1 {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 16px;
  top: 165px;
  line-height: 24px;
  left: 0px;
  transition: 0.85s;
}

.how-to-use-page-caption-hidden-link {
  width: 54px;
  position: absolute;
  top: 342px;
  left: -54px;
  font-size: 9px;
  z-index: 2;
  color: #020202;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.1px;
}

.installation-page-add-chrome-button-arrow {
  position: absolute;
  top: 277px;
  left: 202px;
}

.how-to-use-page-caption-image-text-arrow-1 {
  position: absolute;
  top: 217px;
  left: -7px;
  z-index: 2;
  transition: 0.45s;
  height: 63px;
}

.how-to-use-caption-window-image-as-html-text {
  font-size: 14px;
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  top: 33px;
  left: 14px;
  font-weight: 400;
}

.how-to-use-page-caption-image-text-2 {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 16px;
  line-height: 24px;
  top: 396px;
  left: 27px;
  transition: 0.85s;
}

.how-to-use-page-caption-image-text-arrow-2 {
  position: absolute;
  bottom: 166px;
  left: -45px;
  z-index: 2;
  transition: 0.45s;
  height: 66px;
  width: 65px;
}

/*
    End of Caption window css elements
 */



/*
    Media player testing css elements
 */

.media-player-page-steps-container {
  padding-top: 35px;
  padding-bottom: 55px;
}

.media-player-page-steps {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  position: relative;
}

.media-player-page-step-2 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.media-player-page-video-container {
  width: 508px;
  display: block;
  margin-left: -67px;
}

.media-player-page-video {
  margin-bottom: 10px;
}

.media-player-page-step-1-question {
  position: absolute;
  top: -10px;
  left: 150px;
  cursor: pointer;
}

.media-player-ed-logo {
  width: 34px;
  top: -7px;
  right: 100px;
  position: absolute;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 17px;
}

.media-player-ed-showing-arrow {
  position: absolute;
  left: calc( 50% + 110px );
  top: -205px;
  width: calc( 50vw - 331px );
  height: 346px;
}

.media-player-page-step-2-question {
  position: absolute;
  top: -11px;
  left: 228px;
  cursor: pointer;
}

.media-player-page-steps-add-chrome-button-margin {
  margin-top: 30px;
  max-width: 335px;
}

/*
    End of Media player testing css elements
 */

/*
    Media queries
 */

@media only screen and (max-width: 840px) {

  .how-to-use-page-control-image-text-2-before-animation {
    visibility: hidden;
  }

  .how-to-use-page-control-image-text-3-before-animation {
    visibility: hidden;
  }

  .how-to-use-page-control-image-text-4-before-animation {
    visibility: hidden;
  }

  .how-to-use-page-container {
    left: calc(50vw - 220px);
  }

  .page-indicator-dots-container {
    left: calc(50vw - 290px);
  }

  .media-player-ed-showing-arrow {
    width: calc( 50vw - 275px );
  }

  .open-caption-ed-showing-arrow {
    width: calc( 50vw - 266px );
  }
}

@media only screen and (min-width: 2000px) {
  .hidden-xxl {
    display: none;
  }
}

@media only screen and (min-width: 1800px) and ( max-width: 2000px ) {
  .hidden-xl {
    display: none;
  }
}

@media (min-width: 1600px) and ( max-width: 1800px ) {
  .hidden-lg {
    display: none;
  }
}

@media (min-width: 1200px) and ( max-width: 1600px ) {
  .hidden-md {
    display: none;
  }
}

@media (min-width: 1000px ) and ( max-width: 1200px ) {
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 1000px) {
  .hidden-xs {
    display: none;
  }
}

@media (max-height: 1000px) and ( min-height: 800px ) {
  .how-to-use-page-container {
    top: 150px;
  }

  .page-indicator-dots-container {
    top: 164px;
  }

  .media-player-ed-showing-arrow{
    top: -146px;
    height: 291px;
  }

  .open-caption-ed-showing-arrow {
    height: 483px;
    top: -145px;
  }
}

@media (max-height: 800px) {
  .how-to-use-page-container {
    top: 100px;
  }

  .page-indicator-dots-container {
    top: 114px;
  }

  .media-player-ed-showing-arrow{
    top: -98px;
    height: 240px;
  }

  .open-caption-ed-showing-arrow {
    height: 432px;
    top: -95px;
  }

  .welcome-page-container {
    top: 143px;
  }
}

.past-page {
  top: -100vh;
  position: fixed;
  visibility: hidden;
}

.next-page {
  top: 100vh;
  position: fixed;
  visibility: hidden;
}

/*
    End media queries
 */